/**
* This file is loaded asyncronously in the footer.
* All custom global scripts should be included here.
*/

	/*
	A little info for this js file:

	As much as possible, this file should be kept organized. Currently, two major divisions are seen below, delinitated by their respective functions of "on load" and "ready".
		As mentioned below, most code added to this file should be included within the "on load" function.

	For ease of future use, include any new code at the bottom of the relevant section of the page, adding clear comments to explain the purpose of the code being written,
		including any relavant details regarding what the code overrides, etc.
	*/


(function(){
	'use strict';

	// rerun lazyload on screen size updates
	window.addEventListener( 'resize', function() {
		dbs.lazyload();
	});

	// Load event function ... most custom stuff goes here. Safer for async.
	window.addEventListener( 'load', function() {
		/* Search Form functionality
		 * The click functionality involves three different actions,
		 * depending on conditions the various clicks provide.
		 */
		var searchOpen = document.querySelectorAll( '.search-form__button--open' );
		var searchClose = document.querySelectorAll( '.search-form__button--close' );

		if ( searchOpen ) {
			for (var i=0; searchOpen.length > i; i++) {
				searchOpen[i].addEventListener( 'click', function() {
					this.parentElement.classList.add( 'search-form--active' );
					this.parentElement.querySelector('.site_search__field').focus();
		
					// Hide the search bar after click out of it
					document.getElementById( 'content' ).addEventListener( 'click', function() {
						if ( this.parentElement.classList.contains( 'search-form--active' ) ) {
							this.parentElement.classList.remove( 'search-form--active' );
						}
					});
				});
			}
			
			for (var i=0; searchClose.length > i; i++) {
				searchClose[i].addEventListener( 'click', function() {
					this.parentElement.classList.remove( 'search-form--active' );
				});
			}
		}


		/**
		 * Linkclicker
		 * 
		 * A class placed on a wrapping element with a link inside to make the wrapper clickable.
		 * Note: the container should have only one link.
		 */
		var linkclickers = document.querySelectorAll('.linkclicker');

		for ( var i = 0; linkclickers.length > i; i++ ) {
			var linkclicker = linkclickers[i];

			linkclicker.addEventListener('click', function(e) {
				e.preventDefault();
				var link = this.querySelector('a');
				var linkPath = link.getAttribute('href');
				
				if ( link.getAttribute('target', "_blank") ) {
					window.open(linkPath);
				} else {
					window.location.href = linkPath;
				}
			});
		}


		/**
		 * Force external links/PDFs to open in a new tab.
		 * From https://stackoverflow.com/questions/2910946/test-if-links-are-external-with-jquery-javascript
		 * 2019-07-11 - TA
		 */
		function link_is_external(link_element) {
			return ( link_element.host !== window.location.host || link_element.href.indexOf("pdf") > -1 );
		}

		var anchorElements = document.getElementsByTagName('a');
		for ( let anchorElement of anchorElements ) {

			if ( link_is_external( anchorElement ) ) {
				anchorElement.setAttribute( 'target','_blank' );
				anchorElement.setAttribute( 'rel','noopener' );

				// Add assistive text to alert of external links
				var assistiveText = '<span class="assistive">. External Link. Opens in new window.</span>';
				var assistiveTextContainer = document.createElement( 'span' );
				assistiveTextContainer.innerHTML = assistiveText;
				anchorElement.appendChild( assistiveTextContainer.firstChild );
			}
		}

		/**
		 * Detects if element is in viewport
		 * 
		 * @usage
		 * var el = document.querySelector('.element-class');
		 *
		 * window.addEventListener('scroll', function(event) {
		 * 		if (isInViewport(el)) {
		 *			el.classList.add("in-view");
		 *		}
		 * }, false);
		 * 
		 * NOTE: If the whole layout is taller than the viewport, this will never fire. It is often
		 * better to target one small element inside a layout.
		 */
		function isInViewport(elem) {
			var distance = elem.getBoundingClientRect();
			return (
				distance.top >= 0 &&
				distance.left >= 0 &&
				distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				distance.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		};

		/**
		 * ANOTHER NOTE: Right now the idea is to use this as needed on certain layouts, rather 
		 * than querying all elements of a certain class globally and looping through them. 
		 * However, that is a reasonable approach if there is a significant amount of 
		 * scroll animations being done; especially if it's already on the home page. Here
		 * is an example of how that would be done.
		 */
		// var els = document.querySelectorAll('.animated');
		
		// for (let i = 0; els.length > i; i++) {
		// 	let _this = els[i];
		// 	window.addEventListener('scroll', function(event) {
		// 		if ( isInViewport(_this) ) {
		// 			_this.classList.add("in-view");
		// 		}
		// 	}, false);
		// }

        var attachMenuItem = document.querySelector('.main-menu>.menu>.menu__item:first-child>a');
        var skidSteerSubmenu = document.querySelector('.main-menu>.menu>.menu__item>.menu__submenu>.menu__item:first-child>.menu__submenu');
        var skidSteerSubmenuItem = document.querySelector('.main-menu>.menu>.menu__item>.menu__submenu>.menu__item:first-child');
        
		if (attachMenuItem && skidSteerSubmenuItem) {
			attachMenuItem.addEventListener('mouseover', function() {
				skidSteerSubmenu.style.transitionDelay = '0s';
				setTimeout(function() {
					skidSteerSubmenu.removeAttribute('style');
				}, 400);
			});
			skidSteerSubmenuItem.addEventListener('mouseover', function() {
				skidSteerSubmenu.style.transitionDelay = '.40s';
				setTimeout(function() {
					skidSteerSubmenu.removeAttribute('style');
				}, 400);
			});
		}
	}); // End of load function
	

	// Include ready events here
	window.addEventListener( 'DOMContentLoaded', function() {
		
	});


	/**
	 * Cookie Popup scripts
	 */
	// var popUp		= document.getElementById('cookie-popup');
	// var popUpMain	= document.getElementById('popup-main');

	// // Hide popup and background filter and set cookie
	// function accept() {
	// 	popUp.style.display = "none";
	// 	document.body.classList.add('cookies');
	// 	setCookie( "accepts-cookie", "accepts-cookie-set", 365 );
	// }

	// document.getElementById('accept-button').addEventListener( 'click', function() {
	// 	accept();
	// });

	// var cookie = readCookie( 'accepts-cookie' );
	// // If user already has accepted cookies, don't show popup
	// if ( cookie == 'accepts-cookie-set' ) {
	// 	popUp.style.display = "none";
	// } else {
	// 	// Force screen reader to read popup
	// 	popUpMain.setAttribute("aria-live", "assertive");
	// }
})();
